import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import { Container } from 'react-bootstrap'

import Footer from "./footer"
import Scrollup from "./scrollup"

// 順番崩さない↓
import "./layout.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import "./custom.css"

const LayoutExtra = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
        }
      }
      gwLogoOrange: file(relativePath: { eq: "gw-logo-white.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)


  return (
    <div className="extra-page">
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&family=Noto+Serif+JP:wght@600&display=swap" rel="stylesheet" />
        <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/icons/touch-icon-ipad.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/icons/touch-icon-iphone-retina.png"/>
        <link rel="apple-touch-icon" sizes="167x167" href="/icons/touch-icon-ipad-retina.png"/>
        <script src="https://ajaxzip3.github.io/ajaxzip3.js" charset="UTF-8"></script>
      </Helmet>
      <div id="pagetop"></div>
      <header>
        <Container lg="auto" className="py-3">
          <Img 
            fixed={data.gwLogoOrange.childImageSharp.fixed} 
            alt={data.site.siteMetadata.title}
          />
        </Container>
      </header>
      <main>
        <Container lg="auto">
          {children}
        </Container>
      </main>
      <Footer siteAuthor={data.site.siteMetadata.author} />
      <Scrollup />
    </div>
  )
}

LayoutExtra.propTypes = {
  children: PropTypes.node.isRequired
}

export default LayoutExtra
